<template>
  <div>
    <div class="container shadow-sm bg-light mb-3">
      <div class="row mb-3">
        <div class="col-4">
          <h5 class="mb-3 mt-3">Publishing Settings</h5>
          <input
            type="text"
            class="form-control"
            placeholder="Form Name..."
            v-model="formTitle"
          />
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2">
          <select v-model="formStatus" class="form-control">
            <option value="draft" :selected="formStatus == 'draft'">
              Draft
            </option>
            <option value="published" :selected="formStatus == 'published'">
              Published
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-3 pb-2">
        <div class="col">
          <div class="custom-control custom-switch mb-1">
            <input
              type="checkbox"
              class="custom-control-input"
              id="shareWithClinic"
              v-model="publicForm"
              :checked="publicForm"
            />
            <label class="custom-control-label" for="shareWithClinic"
              >Public Form</label
            >
          </div>

          <div class="d-block bg-light py-1 mb-0">
            <small>
              <i class="fad fa-info-circle me-1 text-success"></i>
              Public forms are available as templates to all Swandoola
              practitioners.
            </small>
          </div>

          <div class="d-block bg-light py-1 mb-0">
            <small>
              <i class="fad fa-info-circle me-1 text-success"></i>
              Private forms can be assigned to clinics or individual
              practitioners, making them available for immediate use in their
              Swandoola app.
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="container shadow-sm bg-light">
      <div class="row">
        <div class="col">
          <h5 class="mt-3">Drag & Drop Form Builder</h5>
        </div>
      </div>
      <div class="row mb-4 pt-2">
        <div class="col">
          <p class="mb-0 text-white bg-secondary p-3">
            Give your form a name, then, drag n' drop elements from the right
            hand side to the left, arrange them, customise them and save your
            form. You can then email a unique link to your clients from their
            profile to complete this form online &amp; it's mobile friendly! You
            do not need to add a submit button as all forms are automatically
            saved whilst being filled in.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div id="fb-editor"></div>
        </div>
      </div>
      <div class="row mb-3 pb-3">
        <div class="col">
          <button class="btn btn-outline-success" @click="createForm">
            <i class="fa fa-save me-1"></i>
            Create Form
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Form Builder
require("jquery-ui-bundle");
require("formBuilder");
export default {
  data() {
    return {
      formBuilder: null,
      formTitle: "",
      formStatus: "draft",
      publicForm: false,
      category: "category",
    };
  },
  methods: {
    createForm() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/form-templates", {
          title: this.formTitle,
          form_options: this.formBuilder.actions.getData(),
          status: this.formStatus,
          public_form: this.publicForm,
          created_by: this.$store.user.id,
          category: this.category,
        })
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          const newUrl = `/form-templates`;
          this.$nextTick(() => {
            window.location.href = newUrl;
          });
        });
    },
  },
  computed: {},
  watch: {},
  mounted() {
    var options = {
      disabledActionButtons: ["data", "save"],
    };
    this.formBuilder = $(document.getElementById("fb-editor")).formBuilder(
      options
    );
  },
};
</script>

<style>
</style>
